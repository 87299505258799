import * as React from "react"
import styled from 'styled-components'

const Foot = styled.footer`
  width: 100%;
  height: 140px;
  background-color: #3c3c3c;
  box-shadow: 1024px 0 0 0 #3c3c3c, -1024px 0 0 0 #3c3c3c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  
  .text {
    font-family: PingFangSC, PingFangSC-Regular;
    text-align: center;
    color: #f1f1f1;
    text-decoration: none;
  }
  .text1 {
    font-size: 16px;
    line-height: 22px;
  }
  .text2 {
    font-size: 14px;
    line-height: 20px;
    
    margin-top: 10px;
  }
  .text3 {
    font-size: 14px;
    line-height: 20px;
    
    margin-top: 10px;
  }
`

const BeiAn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    margin-left: 10px;

    font-family: PingFangSC, PingFangSC-Regular;
    text-align: center;
    color: #f1f1f1;
  }
  
  .beian {
    padding-left: 5px;
  }
`

const Footer = () => (
  <Foot>
    <div className="text text1">上海栖凤网络科技有限公司</div>
    <div className="text text2">
      <span>客服电话：<a href="tel:4000182021" className="text">400-018-2021</a></span>
      <span style={{marginLeft: '20px'}}>微信公众号：buyonepet2021</span>
    </div>
    <BeiAn>
      <span className="text text3">沪ICP备2021008732号-1</span>
      <a target="_blank" href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011702008181'>
        <img src={require('../images/gongan.png').default} alt="gongan" />
        <span className="beian">沪公网安备 31011702008181号</span>
      </a>
    </BeiAn>
  </Foot>
)

export default Footer
